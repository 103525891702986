<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·未入客户库联系人的页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="unInWareHouse">
  	<!--引入未入客户库联系人的页面-->
    <common-wareHouse
      :my-title="myTitle"
      :has-in-warehouse="false"
      @returnCrm="returnCrm"></common-wareHouse>
  </div>
</template>
<script>
  import commonWareHouse from './common_wareHouse.vue'
  export default ({
    data(){
      return{
        myTitle:[
          {name:'未入客户库联系人'}
        ],
      }
    },
    components:{
      commonWareHouse,
    },
    methods:{
      returnCrm(){
        this.$emit('returnCrm')
      }
    },
  })
</script>
